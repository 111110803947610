import { FormEvent, useState } from "react";
import Router, { useRouter } from "next/router";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useUser } from "../lib/hooks";
import {
  Button,
  Center,
  Col,
  Container,
  Grid,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import axios from "axios";
import { PRIMARY_TITLE_COLOR } from "../lib/colors";
import logoImage from "../public/logo.png";

const Login = () => {
  const theme = useMantineTheme();
  const router = useRouter();
  useUser({ redirectTo: "/", redirectIfFound: true });

  const [errorMsg, setErrorMsg] = useState("");

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (errorMsg) {
      setErrorMsg("");
    }

    try {
      const res = await axios.post("/api/auth/login", {
        username: e.currentTarget.username.value,
        password: e.currentTarget.password.value,
      });
      if (res.status === 200) {
        Router.push(
          router.query.from &&
            !Array.isArray(router.query.from) &&
            decodeURIComponent(router.query.from)
            ? decodeURIComponent(router.query.from)
            : "/"
        );
      } else {
        throw new Error(`${res.status}`);
      }
    } catch (error) {
      setErrorMsg("Passwort oder Email-Adresse falsch");
    }
  }

  return (
    <>
      <Head>
        <title>Login - EnglischfürSchüler.de</title>
      </Head>

      <Container style={{ marginTop: theme.spacing.xl }} size="xs">
        <Center>
          <Link href="/" passHref>
            <Image alt="" src={logoImage} width={175} height={50} />
          </Link>
        </Center>
        <Title
          order={1}
          style={{
            color: PRIMARY_TITLE_COLOR,
            marginTop: theme.spacing.lg,
          }}
        >
          Login
        </Title>
        <form onSubmit={handleSubmit} style={{ marginTop: theme.spacing.xs }}>
          <TextInput
            error={errorMsg !== ""}
            label="E-Mail-Adresse"
            name="username"
            required
            type="email"
          />
          <TextInput
            error={errorMsg}
            label="Passwort"
            name="password"
            required
            style={{ marginTop: theme.spacing.xs }}
            type="password"
          />
          <Button
            fullWidth
            style={{ marginTop: theme.spacing.xs }}
            type="submit"
          >
            Login
          </Button>
          <Grid id="Login" mt={theme.spacing.xs}>
            <Col span={6}>
              <Link
                href="/passwort-vergessen"
                passHref
                style={{ marginTop: theme.spacing.xs }}
              >
                Passwort vergessen?
              </Link>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Link
                href={
                  process.env.NEXT_PUBLIC_CLIENT_URL?.includes("localhost")
                    ? "/registrierung"
                    : "https://englischfuerschueler.de/registrierung"
                }
                passHref
                style={{ marginTop: theme.spacing.xs }}
              >
                Noch nicht angemeldet?
              </Link>
            </Col>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default Login;
