import * as Sentry from "@sentry/nextjs";
import Router from "next/router";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { axiosGetLoggedInUser } from "./api/self";

export function useUser({
  redirectTo,
  redirectIfFound,
}: { redirectTo?: string; redirectIfFound?: boolean } = {}) {
  const { data, status } = useQuery("useUserHook", async () => {
    const { data } = await axiosGetLoggedInUser();

    try {
      Sentry.setUser({
        email: data.user?.email ? data.user.email : undefined,
        id: data.user?.id,

        isSubscribed: data.user?.isSubscribed,
        role: data.user?.role,
      });
    } catch (error) {}

    return data;
  });
  const finished = status === "success";
  const hasUser = data && data.user !== null;

  useEffect(() => {
    if (!redirectTo || !finished) {
      return;
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasUser) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasUser)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, finished, hasUser]);

  return status === "error" || !hasUser ? null : data;
}
