import axios from "axios";
import { ReturnedPromiseResolvedType } from "../../types/utility";
import { findUser } from "../user";

export const axiosGetLoggedInUser = async () => {
  return axios.get<ApiSelfGetReturnType>(API_SELF_ROUTE);
};

export const API_SELF_ROUTE = "/api/self" as const;

export type ApiSelfGetReturnType = {
  user: ReturnedPromiseResolvedType<typeof findUser> | null;
};
